<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card max-width="1100" width="100%" flat class="transparent mb-0 mb-md-5">
        <v-card-title flat class="transparent">
          <div class="d-flex">
            <h1 class="text-h5">{{ $t('prescriptions.prescriptions_list') }}</h1>
          </div>
        </v-card-title>

        <v-card-title flat class="transparent">
          <v-btn data-test="prescription-button-search" depressed color="primary" class="order-md-2" @click="getPrescriptions">
            {{ $t('common.search') }}
          </v-btn>
          <v-flex xs12 sm4>
            <v-text-field
              data-test="prescriptions-search-input"
              v-model="search"
              filled
              rounded
              flat
              dense
              clearable
              type="text"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('patient.search')"
              hide-details
              class="pa-0 mt-4 mt-md-0 mr-md-2 order-md-1 mb-2"
            />
          </v-flex>
          <v-flex xs12 sm2 lg2 mr-2>
            <v-select
              data-test="prescriptions-filter-select"
              v-model="prescFilter"
              filled
              dense
              class="mx-1"
              :items="filters"
              :label="$t('common.filters')"
              auto
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-flex xs12 sm2 mr-2>
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              class="mr-2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :return-value.sync="fini"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  data-test="prescriptions-input-start-date"
                  v-model="iniFormatted"
                  :label="$t('common.fecha_inicio')"
                  append-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  @blur="fini = parseDate(iniFormatted)"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="fini"
                first-day-of-week="1"
                :locale="this.$i18n.locale"
                no-title
                scrollable
                @input="$refs.menuStartDate.save(fini)"
              />
            </v-menu>
          </v-flex>
          <v-flex xs12 sm2 mr-2>
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              class="mr-2 ml-2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :return-value.sync="ffin"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  data-test="prescriptions-input-end-date"
                  v-model="finFormatted"
                  :label="$t('common.fecha_fin')"
                  append-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  @blur="ffin = parseDate(finFormatted)"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="ffin"
                first-day-of-week="1"
                :locale="this.$i18n.locale"
                no-title
                scrollable
                @input="$refs.menuEndDate.save(ffin)"
              />
            </v-menu>
          </v-flex>
        </v-card-title>

        <v-card v-if="prescriptions.length > 0" flat class="transparent ml-2">
          <v-flex xs6 sm6>
            <v-checkbox
              v-model="markAll"
              :label="$t('prescriptions.mark_all')"
              color="primary"
              hide-details
              @change="markAllChecks($event)"
            />
          </v-flex>
        </v-card>

        <div v-if="prescriptions.length > 0" class="mt-4">
          <v-card v-for="prescription in prescriptions" :key="prescription.id" class="mt-5">
            <v-row>
              <v-col align="left" class="mr-4 mt-2 ml-4">
                <v-row class="float-right mb-1">
                  <span> {{ $t('recipe.created_at') }} {{ formatTreatmentDate(prescription.createdAt) }}</span>
                  <v-chip
                    medium
                    :color="getPrescriptionStatusColor(prescription.status, prescription.in_queue)"
                    class="white--text mx-3"
                  >
                    {{ getPrescriptionStatus(prescription.status, prescription.in_queue) }}
                  </v-chip>
                </v-row>

                <v-row style="width: 100%; margin-left: 10px;">
                  <v-flex xs1 sm1>
                    <v-checkbox
                      :id="'check' + prescription.id"
                      v-model="checks"
                      :disabled="prescription.in_queue"
                      :value="prescription.id"
                    />
                  </v-flex>
                  <v-flex xs10 sm10>
                    <div class="d-flex flex-row align-center">
                      <div class="m-2 mt-2 ml-2 flex-grow-3">
                        <span style="font-size: medium;">{{ prescription.patient.fullName }}</span
                        ><br />
                        <span style="font-size: medium;">{{
                          calculateAge(prescription.patient.birthDate) + ' ' + $t('recipe.years')
                        }}</span>
                      </div>
                      <div class="mt-2 ml-2 px-4 flex-grow-8">
                        <v-btn
                          depressed
                          color="primary"
                          small
                          class="order-md-2"
                          @click="onGoToPrescription(prescription.id)"
                        >
                          {{ $t('recipe.navigate_to_prescription') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-flex>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" class="ma-2 pa-2">
              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >{{
                      $t('prescriptions.medicamentsIncluded', {
                        numMedicaments: prescription.medicines.length + prescription.masterFormulas.length,
                      })
                    }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="medicine in prescription.medicines" :key="medicine.id">
                      <h4>- {{ medicine.name }}</h4>
                      <p>
                        {{ medicine.guideline }}
                        {{ getAdministrationUnit(medicine.administrationUnit, medicine.guideline) }}
                        {{ $t('recipe.every') }}
                        {{ medicine.posology + ' ' + translateDurationPosology(medicine.posologyMeasure) }}
                        {{ $t('recipe.during').toLowerCase() }}
                        {{ medicine.duration + ' ' + translateDurationMeasure(medicine.durationMeasure) }},
                        {{ $t('recipe.from') }} {{ formatTreatmentDate(medicine.dateStart) }} {{ $t('recipe.to') }}
                        {{ formatTreatmentDate(medicine.dateEnd) }}
                      </p>
                    </div>
                    <div v-for="masterFormula in prescription.masterFormulas" :key="masterFormula.id">
                      <h4>- {{ masterFormula.composition }}</h4>
                      <h4 class="ml-2">{{ masterFormula.dosage }}</h4>
                      <p>
                        {{ masterFormula.guideline }} {{ masterFormula.guidelineMeasure }} {{ $t('recipe.every') }}
                        {{ masterFormula.posology + ' ' + masterFormula.posologyMeasure }}
                        {{ $t('recipe.during').toLowerCase() }}
                        {{ masterFormula.duration + ' ' + masterFormula.durationMeasure }}
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card>
          <div v-show="showPaginator" class="text-center py-5">
            <v-pagination v-model="page" circle :length="pageCount" @input="onChangePage" />
          </div>
        </div>
        <div v-else>
          <v-card-title flat class="transparent">
            <v-flex xs12 sm12>
              {{ $t('prescriptions.not_found') }}
            </v-flex>
          </v-card-title>
        </div>

        <v-card-title v-if="prescriptions.length > 0" flat class="transparent">
          <v-flex xs12 sm12>
            <v-checkbox
              v-model="patientConfirmation"
              :error="patientConfirmationError"
              :label="$t('prescriptions.patientsIndentityConfirmationText')"
              @change="$event && (patientConfirmationError = false)"
            />
          </v-flex>
        </v-card-title>

        <span class="ml-8"> {{ selectedText }}</span>
        <v-card-title v-if="prescriptions.length > 0" flat class="transparent">
          <v-flex xs12 sm3 lg3 mr-3>
            <v-select
              v-model="actionsFilter"
              filled
              dense
              class="mx-1"
              :items="actions"
              :label="$t('prescriptions.lote_actions')"
              auto
              required
              item-text="name"
              item-value="id"
            />
          </v-flex>
          <v-btn
            depressed
            color="primary"
            class="order-md-2 mb-1"
            :disabled="checkIfDisable"
            @click="checkToEmitCommand"
          >
            {{ $t('common.confirm') }}
          </v-btn>
        </v-card-title>
      </v-card>
    </v-row>

    <!-- 2fas pincode dialog -->
    <two-fa-pincode-dialog
      :key="pincodeKey"
      :show="showPincodePrompt"
      :text="$t('2fa.verifyCodePrompText')"
      :accept-button-text="$t('2fa.verify')"
      @close="showPincodePrompt = false"
      @pincode="handlePincode"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getPrescriptionsSearchUrl, batchEmitPrescriptionsUrl } from '@/config/config';
import { getPrescriptionStatus } from '@/utils/getPrescriptionStatus';
import TwoFaPrescriptionInterceptDialogMixin from '@/mixins/TwoFaPrescriptionInterceptDialogMixin.js';
import { navigateToPatientWithPrescription } from '@/services/navigation';
import { deletePendingPrescriptionsBatch } from '@/services/electronic-prescriptions';

export default {
  ...mapGetters({
    twoFaEnabled: 'account/twoFaEnabled',
  }),

  components: {},
  mixins: [TwoFaPrescriptionInterceptDialogMixin],
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),

    page: 1,
    pageCount: null,
    itemsPerPage: 20,
    showPaginator: false,

    search: '',
    fini: null,
    ffin: null,
    menuStartDate: null,
    menuEndDate: null,
    iniFormatted: '',
    finFormatted: '',
    prescFilter: '1',
    filters: [{ id: '1', name: vm.$t('prescriptions.filterPending') }],
    actionsFilter: 'firm_emit',
    actions: null,
    markAll: false,
    patientConfirmationError: false,
    patientConfirmation: false,

    checks: [],
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },

    prescriptions: [],
    selectedPrescriptions: [],
  }),
  computed: {
    checkIfDisable: function() {
      return this.checks.length === 0 || !this.twoFaEnabled;
    },
    selectedText: function() {
      if (this.checks.length > 0) {
        return this.checks.length + this.$t('prescriptions.itemsSelected');
      } else {
        return '';
      }
    },
    ...mapGetters({
      twoFaEnabled: 'account/twoFaEnabled',
      repHomologation: 'app/getFeatureRepHomologationFlag',
    }),
  },
  watch: {
    search(filter) {
      let filterResult = [...this.pacientes];
      if (this.search) {
        const filterRegExp = new RegExp(filter, 'i');
        filterResult = filterResult.filter(
          patient =>
            (patient.fullName && patient.fullName.match(filterRegExp)) ||
            (patient.fecha_alta && patient.fecha_alta.match(filterRegExp)) ||
            (patient.created_at && patient.created_at.match(filterRegExp)) ||
            (patient.email && patient.email.match(filterRegExp))
        );
      }
      this.filteredPatients = filterResult.sort((a, b) => b.created_at.localeCompare(a.created_at));
    },
    fini() {
      this.iniFormatted = this.formatDate(this.fini);
    },
    ffin() {
      this.finFormatted = this.formatDate(this.ffin);
    },
  },
  mounted() {
    this.actions = [
      { id: 'firm_emit', name: this.$t('common.emitSignPrescription'), method: 'emmitBatchPrescriptions', },
      { id: 'delete', name: this.$t('prescriptions.deletePrescriptions'), method: 'deleteBatchPrescriptions', },
    ];
    this.getPrescriptions();
  },
  methods: {
    ...mapActions('layout', ['showAlert']),
    ...mapActions('overlay', ['handleOverlay']),

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    formatTreatmentDate(inputDate) {
      if (!inputDate) return null;

      const date = inputDate.substring(0, 10);
      const day = date.replace(/^(\w{4})-(\w{2})-(\w{2})$/g, '$3');
      const month = date.replace(/^(\w{4})-(\w{2})-(\w{2})$/g, '$2');
      const year = date.replace(/^(\w{4})-(\w{2})-(\w{2})$/g, '$1');

      return String(day + '/' + month + '/' + year);
    },

    calculateAge(birthDate) {
      const today = new Date();
      const birth = new Date(birthDate);
      let age = today.getFullYear() - birth.getFullYear();
      const m = today.getMonth() - birth.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
        age--;
      }
      return age;
    },

    async getPrescriptions() {
      const data = {};

      if (this.search !== '') {
        data.patientName = this.search;
      }

      data.status = this.prescFilter;
      data.limit = this.itemsPerPage;
      data.page = this.page;

      if (this.fini !== null) {
        data.dateStart = this.fini;
      }

      if (this.ffin !== null) {
        data.dateEnd = this.ffin;
      }

      await this.handleOverlay(true);

      this.$http
        .post(getPrescriptionsSearchUrl, data, { headers: this.getRequestHeaders() })
        .then(response => {
          if (response.status === 200) {
            this.prescriptions = response.data.data;
            this.pageCount = Math.ceil(response.data._meta.total / response.data._meta.limit);
            this.page = response.data._meta.page;
            this.showPaginator = response.data._meta.count !== response.data._meta.total;
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = this.$t(err?.data?.error?.message || 'errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(async () => {
          await this.handleOverlay(false);
        });
    },

    getPrescriptionStatus(status, in_queue) {
      if (in_queue) {
        return this.$t('recipe.in_queue');
      }
      return getPrescriptionStatus(status);
    },

    getPrescriptionStatusColor(status, in_queue) {
      if (in_queue) {
        return 'green';
      }
      return 'orange';
    },

    checkToEmitCommand() {
      if (this.checkForPatientConfirmationBeforeSave()) {
        this.check2fa();
      }
    },

    checkForPatientConfirmationBeforeSave() {
      if (!this.patientConfirmation) {
        this.patientConfirmationError = true;
        return false;
      }
      return true;
    },

    getBatchMethodToProceedWith(selectedAction) {
      return this.actions.find((action) => action.id === selectedAction).method;
    },

    check2fa() {
      if (this.twoFaEnabled && this.repHomologation) {
        this.showPincodePrompt = true;

        this.methodToProceedWith = {
          name: this.getBatchMethodToProceedWith(this.actionsFilter),
          params: [],
        };
      } else {
        this[this.getBatchMethodToProceedWith(this.actionsFilter)]();
      }
    },

    async emmitBatchPrescriptions() {
      this.showPincodePrompt = false;
      await this.handleOverlay(true);

      const prescriptions = [];
      this.checks.forEach(prescription => {
        prescriptions.push({ id: prescription });
      });

      this.$http
        .post(
          batchEmitPrescriptionsUrl,
          { prescriptions: prescriptions, user_id: this.authUser.id },
          { headers: this.getRequestHeaders() }
        )
        .then(response => {
          if (response.status === 204) {
            this.checks = [];
            this.getPrescriptions();
          } else {
            this.$toast.error(this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = this.$t(err?.data?.error?.message || 'errors.try_again');
          this.$toast.error(textAlert);
        })
        .finally(async () => {
          this.resetPincodeDialog();
          await this.handleOverlay(false);
        });
    },

    async deleteBatchPrescriptions() {
      this.showPincodePrompt = false;
      await this.handleOverlay(true);

      const prescriptions = [];
      this.checks.forEach(prescription => {
        prescriptions.push({ id: prescription });
      });

      try {
        await deletePendingPrescriptionsBatch(prescriptions);
        this.checks = [];
        await this.getPrescriptions();
      } catch (err) {
        const textAlert = this.$t(err?.data?.error?.message || 'errors.try_again');
        this.$toast.error(textAlert);
      } finally {
        this.resetPincodeDialog();
        await this.handleOverlay(false);
      }
    },

    markAllChecks(checked) {
      this.checks = [];
      this.prescriptions.forEach(prescription => {
        if (!prescription.in_queue) {
          if (checked) {
            this.checks.push(prescription.id);
          }
        }
      });
    },

    onChangePage() {
      this.getPrescriptions();
    },

    translateDurationMeasure(duration_measure) {
      return this.$te(`recipe.${duration_measure}`) ? this.$t(`recipe.${duration_measure}`) : duration_measure;
    },

    translateDurationPosology(duration_posology) {
      return this.$te(`recipe.${duration_posology}`) ? this.$t(`recipe.${duration_posology}`) : duration_posology;
    },

    getAdministrationUnit(administrationUnit, units) {
      return units <= 1 ? administrationUnit.singular : administrationUnit.plural;
    },

    onGoToPrescription(id) {
      navigateToPatientWithPrescription(
        id,
        this.prescriptions.find(prescription => prescription.id === id).patient.id,
        this.$router
      );
    },
  },
};
</script>
<style scoped lang="css">
.clickeable {
  cursor: pointer;
}

.loading_paciente {
  margin-top: 30px;
  text-align: center;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.flex-grow-3 {
  flex-grow: 3;
}
.flex-grow-8 {
  flex-grow: 8;
}
</style>
